@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media

.bs-w-affiliate-disclosure
  padding: rem-fn(30)

  @include media-query.from(media.$md-min)
    margin-inline: auto
    max-inline-size: rem-fn(media.$content-max-width)
